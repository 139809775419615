import cn from "classnames"
import { Fragment } from "react"

import { getColorBySeverity } from "@/domain/vulnerability"

import styles from "./SeverityTag.module.scss"

import type { SeverityTagProps } from "."

export const SeverityTag = ({
  className,
  severity,
  t,
  latter,
  cvss
}: SeverityTagProps) => {
  const text = t(`vulnerabilities.${severity.toLowerCase()}`)
  const withCvss = cvss !== undefined
  const Wrapper = withCvss ? "span" : Fragment
  const propsWrapper = withCvss ? { className: styles.wrapper } : {}

  return (
    <Wrapper {...propsWrapper}>
      <span
        style={{ backgroundColor: getColorBySeverity(severity) }}
        className={cn(styles.container, latter && styles.latter, className)}
        data-tooltip-content={text}
      >
        <span>{text}</span>
      </span>
      {cvss !== undefined && <span>{` (CVSS ${cvss})`}</span>}
    </Wrapper>
  )
}
