import cn from "classnames"

import { sanitizeString } from "@/helpers/string.helpers"
import showdown from "showdown"
import { MarkdownProps } from "."

import { useMemo, useRef } from "react"
import styles from "./Markdown.module.scss"

export const Markdown = (props: MarkdownProps) => {
  const { content, className } = props
  // Store converter instance
  const converter = useRef<showdown.Converter>(
    new showdown.Converter({
      requireSpaceBeforeHeadingText: true,
      simpleLineBreaks: true,
      smartIndentationFix: true,
      omitExtraWLInCodeBlocks: true
    })
  )

  const htmlContent = useMemo(() => {
    return converter.current.makeHtml(sanitizeString(content))
  }, [content])

  return (
    <span
      className={cn(styles.githubMarkdown, className)}
      dangerouslySetInnerHTML={{
        __html: htmlContent
      }}
    />
  )
}
