import type { Issue, RuntimeEvidenceIssue, Type } from "."

export const isCodeIssueType = (issueType: Type) =>
  issueType === "Vulnerable_Code" || issueType === "Exposed_Secret"
export const isCodeIssue = (issue: Issue) => isCodeIssueType(issue.issueType)
export { getColorBySeverity } from "../vulnerability"

export const isPackageIssueType = (issueType: Type) =>
  issueType === "Vulnerable_Package"

export const getLanguage = (issue: Pick<Issue, "language">) => issue.language

export const getRiskInsights = (issue: Pick<Issue, "riskInsights">) =>
  issue.riskInsights || {}

export const isRuntime = (issue: Pick<Issue, "riskInsights">) =>
  !!getRiskInsights(issue).runtime

// start - Runtime Evidence

export const getRuntimeEvidence = (issue: RuntimeEvidenceIssue) =>
  getRiskInsights(issue).runtimeEvidence || {}

export const getFunctions = (issue: RuntimeEvidenceIssue) =>
  getRuntimeEvidence(issue).functions || {}

export const getClasses = (issue: RuntimeEvidenceIssue) =>
  getRuntimeEvidence(issue).classes || {}

export const getFunctionCount = (issue: RuntimeEvidenceIssue) =>
  getFunctions(issue).length || 0

export const getRuntimeEvidenceType = (issue: RuntimeEvidenceIssue) =>
  getRuntimeEvidence(issue).type

export const isFromPackageEvidence = (issue: RuntimeEvidenceIssue) => {
  const type = getRuntimeEvidenceType(issue)

  return (
    type === "package_in_runtime" ||
    type === "package_not_in_runtime" ||
    type === "package_loaded" ||
    type === "undetermined"
  )
}

export const isFromClassEvidence = (issue: RuntimeEvidenceIssue) => {
  const type = getRuntimeEvidenceType(issue)

  return type === "class_in_runtime" || type === "all_classes_not_in_runtime"
}

export const isFromFunctionEvidence = (issue: RuntimeEvidenceIssue) => {
  const type = getRuntimeEvidenceType(issue)

  return type === "functions_in_runtime" || type === "functions_not_in_runtime"
}

export const isJvmLanguage = (issue: RuntimeEvidenceIssue) => {
  const language = getLanguage(issue)

  return language === "java" || language === "scala" // TODO: add Kotlin
}

export const displayOneClass = (classes: string[], isClassLevel: boolean) => {
  const countClasses = classes?.length || 0
  const noClasses = countClasses === 0
  const displayClasses = !noClasses && isClassLevel
  return displayClasses && countClasses === 1
}

export const getDisplayClassesTranslationKey = (
  issue: RuntimeEvidenceIssue
) => {
  const displayKeysMap = {
    runtime: {
      JVM: "runtimeClassesEvidence",
      other: "runtimeFilesEvidence"
    },
    notRuntime: {
      JVM: "notInRuntimeClassesEvidence",
      other: "notInRuntimeFilesEvidence"
    }
  }

  return displayKeysMap[isRuntime(issue) ? "runtime" : "notRuntime"][
    isJvmLanguage(issue) ? "JVM" : "other"
  ]
}

export const getDisplayFunctionsTranslationKey = (
  issue: RuntimeEvidenceIssue
) =>
  isRuntime(issue) ? "runtimeFunctionEvidence" : "notInRuntimeFunctionEvidence"

export const isPackageRuntime = (issue: RuntimeEvidenceIssue) =>
  isFromPackageEvidence(issue) && isRuntime(issue)

export const isPackageRuntimeAndJvm = (issue: RuntimeEvidenceIssue) =>
  isPackageRuntime(issue) && isJvmLanguage(issue)

export const isPackageRuntimeAndNotJvm = (issue: RuntimeEvidenceIssue) =>
  isPackageRuntime(issue) && !isJvmLanguage(issue)

export const isPackageNotRuntime = (issue: RuntimeEvidenceIssue) =>
  isFromPackageEvidence(issue) && !isRuntime(issue)

export const isPackageLoadedNoRuntime = (issue: RuntimeEvidenceIssue) => {
  const type = getRuntimeEvidenceType(issue)
  return isPackageNotRuntime(issue) && type === "package_loaded"
}

export const isPackageNotRuntimeAndJvm = (issue: RuntimeEvidenceIssue) =>
  isPackageNotRuntime(issue) &&
  isJvmLanguage(issue) &&
  !isPackageLoadedNoRuntime(issue)

export const isPackageNotRuntimeAndNotJvm = (issue: RuntimeEvidenceIssue) =>
  isPackageNotRuntime(issue) &&
  !isJvmLanguage(issue) &&
  !isPackageLoadedNoRuntime(issue)

// end - Runtime Evidence
